import { getDashboard } from '../../services/dashboard.services'
import { mapGetters } from 'vuex'
import { duplicateObject, objEmpty } from '../../helpers/helpers'
import { Contractschart } from "../../factories/staticGraphicFactory"
import moment from 'moment'
export const ContractsMixin = {
    data() {
        return {
            log: [],
            loading: true,
            dashboard: {},
            dashboardClean: {},
            updated: '',
            statsGraphic: {},
            period: 1,
            chartPeriod: 'daily',
            filt: {
                status: '',
                user: '',
                data: '',
                contratoID: ''
            },
            contratos: [],
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            },
            title: '',
        };
    },
    created() {},
    mounted() {
        this.callcontratos(1)
    },
    watch: {},
    computed: {
        ...mapGetters(['getClientid']),
        emptyStats: function() {
            return objEmpty(this.statsGraphic)
        },
        emptycontratos: function() {
            if (this.contratos.length === 0) return true
            else return false
        }
    },
    methods: {
        async callcontratos(period) {
            //console.log(this.emptyStats)
            //console.log(this.emptycontratos)
            this.period = period
            try {
                this.loading = true
                let initial = {
                    type: 'byPeriod',
                    period: this.period,
                }
                const { data } = await getDashboard(initial)
                this.dashboard = duplicateObject(data.data)
                this.contratos = duplicateObject(this.dashboard.contratos)
                this.graphicMount(duplicateObject(this.dashboard.contratos))
                this.loading = false
            } catch (error) {
                this.loading = false
                throw console.log(error)
            }
        },
        async getbyPeriod() {
            this.period = 'custom'
            try {
                this.loading = true
                let initial = {
                    period: 'byDate',
                    startDate: moment(this.dateRange.startDate).format("L"),
                    endDate: moment(this.dateRange.endDate).format("L"),
                }
                const { data } = await getContracts(initial)
                    //console.log(data)
                this.dashboard = duplicateObject(data.data)
                this.contratos = duplicateObject(this.dashboard.contratos)
                this.graphicMount(duplicateObject(this.dashboard.contratos))
                this.loading = false
            } catch (error) {
                this.loading = false
                throw console.log(error)
            }
        },
        getFromcalendar() {
            this.getbyPeriod()
        },
        graphicMount(data = duplicateObject(this.dashboard.contratos), period = 'daily') {
            this.statsGraphic = new Contractschart(period)
            this.statsGraphic.labels = this.statsGraphic.mountLabels(data)
            this.statsGraphic.stats = this.statsGraphic.mountStats(data)
            this.statsGraphic.mountChart(this.statsGraphic.labels, this.statsGraphic.stats, data)
            this.statsGraphic.mountList(this.statsGraphic.labels, this.statsGraphic.stats, data)
        },
        cleanSearch() {
            this.filt.status = '',
                this.filt.user = '',
                this.filt.contratoID = '',
                this.filt.data = ''
        },
        filterbyUser() {
            this.contratos = this.contratos.filter((val) => {
                if (this.filt.user.match(val.user)) {
                    return val
                }
            })
            if (this.contratos.length === 0) {
                this.contratos = duplicateObject(this.dashboard.contratos)
                this.cleanSearch()
            }
            this.$forceUpdate();
        },
        filterbyStatus() {
            this.contratos = this.contratos.filter((val) => {
                if (parseInt(this.filt.status) === val.progress) {
                    return val
                }
            })
            if (this.contratos.length === 0) {
                this.contratos = duplicateObject(this.dashboard.contratos)
                this.cleanSearch()
            }
            this.$forceUpdate();
        },
        filterbyID() {
            this.contratos = this.contratos.filter((val) => {
                if (this.filt.contratoID === val.id) {
                    return val
                }
            })
            if (this.contratos.length === 0) {
                this.contratos = duplicateObject(this.dashboard.contratos)
                this.cleanSearch()
            }
            this.$forceUpdate();
        },
        filterbyDate() {
            this.contratos = this.contratos.filter((val) => {
                if (this.filt.data === val.data) {
                    return val
                }
            })
            if (this.contratos.length === 0) {
                this.contratos = duplicateObject(this.dashboard.contratos)
                this.cleanSearch()
            }
            this.$forceUpdate();
        },
    }
};