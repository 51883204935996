<template>
  <div class="container-fluid dashboard" >
    <LoaderComponent v-if="loading" />
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="colorCard">
              <h2>Contratos</h2>
              <h6>Last {{ period }} days</h6>
              <h3>{{ dashboard.timely_contratos }}</h3>
            </div>
          </div>
          <div class="col-md-9 buttons">
            <div class="row">
              <div class="col-md-12">
                <div
                  class="btn-toolbar"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <div
                    class="btn-group mr-4"
                    role="group"
                    aria-label="First group"
                  >
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="callContratos(2)"
                    >
                      2 dias
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="callContratos(7)"
                    >
                      7 dias
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="callcontratos(30)"
                    >
                      30 dias
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="callcontratos(45)"
                    >
                      45 dias
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="callcontratos(60)"
                    >
                      60 dias
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="callcontratos('all')"
                    >
                      Todo o período
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <date-range-picker
                  v-model="dateRange"
                  @update="getbyPeriod()"
                ></date-range-picker>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container chartPeriod">
          <div class="row">
            <div class="col-md-6" @click="graphicMount(undefined,'daily')"><button>Diário</button></div>
            <div class="col-md-6" @click="graphicMount(undefined,'monthly')"><button>Mensal</button></div>
          </div>
      </div>
      <div class="container chart">
        <div class="row">
          <div class="col-md-12">
            <Barchart
              v-if="!emptyStats"
              :chartData.sync="statsGraphic.line.data"
              :chartOptions.sync="statsGraphic.options"
              :styles.sync="statsGraphic.line.styles"
            />
          </div>
        </div>
      </div>
      <List
        v-if="!emptyStats"
        :fields.sync="statsGraphic.list"
        :type="'contractsProccess'"
        :numberpages="20"
        :updated.sync="updated"
        :title="`Total de contratos processados`"
      />
      <div class="container filter">
        <div class="row">
          <div class="col-md-6">
            <label>Status do contrato:</label>
            <input
              type="text"
              class="form-group"
              v-model="filt.status"
              @change="filterbyStatus()"
            />
          </div>

          <div class="col-md-6">
            <label>Contrato ID:</label>
            <input
              type="text"
              class="form-group"
              v-model="filt.contratoID"
              @change="filterbyID()"
            />
          </div>
        </div>
      </div>
    <List
        v-if="!emptycontratos"
        :fields.sync="contratos"
        :type="'contractsInside'"
        :numberpages="20"
        :updated.sync="updated"
        :title="`Lista de Contratos - last ${period} days - ${contratos.length} contratos`"
      />
  </div>
</template>
<script type="text/javascript">
import { ContractsMixin } from "./Contracts.mixin.js";
import List from "../../components/ui/list/list";
import Barchart from "../../components/ui/Barchart/Barchart";
import DateRangePicker from 'vue2-daterange-picker'
import LoaderComponent from "../../components/ui/loader/loader";
//you need to import the CSS manually (in case you want to override it)
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
export default {
  data () {
    return {
    }
  },
  watch: {
  },
  props: {},
  components: { List, Barchart, DateRangePicker,LoaderComponent },
  name: "Contracts",
  mixins: [ContractsMixin],
};
</script>
<style src="./Contracts.less" lang="less" />
